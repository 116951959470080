import {connect} from "react-redux";
import React from "react";
import {SharingInstance} from "./sharingInstanceContainer";
import {ChooseSetFromUrl, ShareSetWithPublic} from "../utils/api_callbacks";
import {IsAdminOrCreator, IsAtLeastManager, IsAtLeastReader} from "../utils/tools";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {AutoComplete, Button, Form, List, Switch, Tooltip} from "antd";
import axios from "axios";
import {getUserID, getUserToken} from "../utils/CookieManagement";
import ChosenSetTopPanel from "../Dataset/ChosenSetTopPanel";

const {Option} = AutoComplete;

function ApplyFiltering(data, filtertype, type, chosen_set) {
    if (filtertype === "choosenset") {
        return data.filter(key => key.id_base === chosen_set && (type === "user" ? key.id_who !== null : key.id_group !== null));
    }

    return data;
}

class SharingListView extends React.Component {

    state = {
        invited_email: null,
    }

    ValidateEmail = param_mail => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(param_mail);
    }

    sendDatasetEmailInvit = e => {
        let error = false;
        if (!this.ValidateEmail(this.state.invited_email)) {
            alert("You have entered an invalid email address!")
            error = true;
            // do something
        }

        if (!error) {
            //send data to django !
            axios({
                method: 'post',
                url: '/api/dataset-share-invit/',
                data: {
                    id_dataset: this.props.chosen_set,
                    id_people: getUserID(),
                    email: this.state.invited_email
                },
                headers: {Authorization: `Token ${getUserToken()}`}
            })
        }
    }

    componentDidMount() {
        ChooseSetFromUrl(window.location.pathname, this.props.chosen_set);
    }

    render() {
        const chosen_set = this.props.chosen_set;
        if (this.props.datasets === undefined) {
            return <div></div>
        }
        const chosen_dataset = this.props.datasets[parseInt(chosen_set)]
        if (chosen_dataset === undefined) return <div></div>
        const user_list = this.props.user_contacts;
        console.log(user_list);
        const group_list = Object.values(this.props.groups);
        console.log(group_list);
        const sharings_user = ApplyFiltering(Object.values(this.props.sharings), "choosenset", "user", chosen_set);
        const sharings_group = ApplyFiltering(Object.values(this.props.sharings), "choosenset", "group", chosen_set);

        const is_public = Object.values(this.props.sharings).some(sharing => sharing.id_base === chosen_set && sharing.base === "dataset" && sharing.id_who === 0) || chosen_dataset.public;
        return <div>
            <ChosenSetTopPanel {...this.props} set={chosen_dataset}></ChosenSetTopPanel>
            <br></br>
            {chosen_dataset!==undefined &&<p className={"DatasetDescriptionName"}>{chosen_dataset.name}{IsAdminOrCreator(this.props.chosen_set) ? ` (id ${chosen_dataset.id})` : ""}</p>}
            {
                this.props.connected && IsAtLeastReader(this.props.chosen_set) &&
                <Form style={{marginTop:'1%'}}>
                        <span style={{fontSize: '21px', color: 'black'}}>Sharings</span>
                    <br></br>
                    <br></br>
                    {/* Is public ? */}
                    <div>
                        <h3 style={{color: '#44D4FF', fontSize: '20px',display:'inline'}}>Share this dataset with public </h3>
                        <Tooltip placement="top" title={<span>The dataset will be readable by all users</span>}><Switch
                            checkedChildren={<CheckOutlined/>}
                            disabled={!IsAtLeastManager(chosen_set)}
                            unCheckedChildren={<CloseOutlined/>}
                            checked={is_public}
                            onChange={(checked) => ShareSetWithPublic(checked,parseInt(chosen_set))}
                            style={{marginLeft: '25px',display:'inline'}}
                        /></Tooltip></div>
                    <div style={{marginTop:"1%"}}></div>

                    {
                        group_list !== undefined && group_list.length > 0 &&
                        <Form.Item>
                            <h3 style={{color: '#44D4FF', fontSize: '20px'}}>Share with group </h3>
                            <List
                                itemLayout="horizontal"
                                style={{width: '100%'}}
                                disabled={!IsAtLeastManager(chosen_set)}
                                dataSource={group_list}
                                renderItem={item => (
                                    <SharingInstance type={"group"} id_set={chosen_set} set_owner={chosen_dataset.id_people} sharings={sharings_group}
                                                     group={item}></SharingInstance>
                                )}

                            />
                        </Form.Item>
                    }

                    {
                        user_list !== undefined && user_list.length > 0 &&
                        <Form.Item>
                            <h3 style={{color: '#44D4FF', fontSize: '20px'}}>Already shared users </h3>
                            <List
                                itemLayout="horizontal"
                                style={{width: '100%'}}
                                disabled={!IsAtLeastManager(chosen_set)}
                                dataSource={user_list}
                                renderItem={item => (
                                    <SharingInstance type={"user"} id_set={chosen_set} set_owner={chosen_dataset.id_people} sharings={sharings_user}
                                                     user={item}></SharingInstance>
                                )}

                            />
                        </Form.Item>
                    }



                    <Form.Item>
                        <h3 style={{color: '#44D4FF', fontSize: '20px'}}>Share this dataset with a specific user</h3>
                        <AutoComplete disabled={!IsAtLeastManager(chosen_set)} style={{width: "30%"}}
                                      placeholder="User email"
                                      onChange={value => this.setState({invited_email: value})}>
                            {
                                user_list.map(user => <Option key={user.email} value={user.email}>{user.email}</Option>)
                            }
                        </AutoComplete>
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={!IsAtLeastManager(chosen_set)} type="primary"
                                onClick={this.sendDatasetEmailInvit}
                                style={{borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginRight: '10px'}}>
                            Send invit
                        </Button>
                    </Form.Item>
                </Form>
            }
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        datasets: state.dataset.entities,
        sharings: state.sharing.entities,
        groups: state.user.group_list,
        connected: state.user.is_connected,
        user_contacts: state.user.user_contacts,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(SharingListView);